import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";

// import { LinkContainer } from "react-router-bootstrap";

import Routes from "./Routes";
import './App.css';

class App extends Component {

  render() {

    const childProps = {
      // isAuthenticated: this.state.isAuthenticated,
      // userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Connect with HSX</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          {/* <Navbar.Collapse>
            <Nav pullRight>
              <LinkContainer to="/signup?client_id=709kn55vsc8rmpndjk2inuurnf">
                <NavItem>Signup</NavItem>
              </LinkContainer>
              <LinkContainer to="/login">
                <NavItem>Login</NavItem>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse> */}
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );    
  }
  
}

export default App;
