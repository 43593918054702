import React, { Component } from "react";

import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel,
  Alert
} from "react-bootstrap";

// Rest client
import axios from 'axios';

// Help dealing with query string
import queryString from 'query-string';

// Library to handle normalizing phone numbers
import { parseNumber } from 'libphonenumber-js';

// Custom button to display loading indicator while the page is working
import LoaderButton from "../components/LoaderButton";

// Standard Button control from Bootstrap
import { Button } from "react-bootstrap"; 

// Signup styles
import "./SignUp.css";

const httpClient = axios.create();



const stage = process.env.REACT_APP_STAGE;
// console.log('Staring in stage: ', stage);

let SetupAuthorizeUrl = function(){
  if(stage === 'sandbox') {
    // console.log('Setting up sandbox AUTHORIZE_URL variable.');
    return 'https://api.hsx.io/oauth/proxy';
  } else {
    // console.log('Setting up '+ stage + ' AUTHORIZE_URL variable.');
    return 'https://' + process.env.REACT_APP_STAGE + '.api.healthshareexchange.org/oauth/proxy';
  }
}

let SetupGetClientInfoUrl = function(){
  if(stage === 'sandbox') {
    // console.log('Setting up sandbox CLIENT_INFO_URL variable.');
    return 'https://api.hsx.io/oauth/clientinfo?client_id=';
  } else {
    // console.log('Setting up '+ stage + ' CLIENT_INFO_URL variable.');
    return 'https://' + process.env.REACT_APP_STAGE + '.api.healthshareexchange.org/oauth/clientinfo?client_id=';
  }
}

const hsx_authorize_url = SetupAuthorizeUrl();
const hsx_getClientInfo_url = SetupGetClientInfoUrl(); 

let mirthVerifierTimeout = '120000';
let mirthVerifierTimeoutFunction;

export default class Signup extends Component {

  constructor(props) {

    super(props);

    // Setup the application state
    this.state = {
      stage: process.env.REACT_APP_STAGE,
      session: '',
      isLoading: false,
      isUnavailable: null,
      given_name: '',
      middle_name: '',
      family_name: '',
      birthdate: '',
      gender: '',
      phone_number: '',
      email: '',
      username: '',
      smsConfirmationCode: '',
      smsChallengeSent: false,
      smsChallengeComplete: false,
      emailConfirmationCode: '',
      emailChallengeSent: false,
      emailChallengeComplete: false,
      scopeChallengeComplete: false,
      scopesAccepted: false,
      scopesRejected: false,
      isUnverified: false,
      user: null,
      error: null,
      isScopesRejected: null
    };
    
  }

  // Validate the form fields before submit
  validateForm() {
    return (
      this.state.given_name.length > 0 &&
      this.state.family_name.length > 0 &&
      this.state.birthdate.length > 0 &&
      this.state.email.length > 0 &&
      this.state.phone_number.length > 0 &&
      this.state.gender.length > 0 
    );
  }

  // Validate an SMS verification code was entered before submit
  validateSmsConfirmationForm() {
    return this.state.smsConfirmationCode.length > 0;
  }

  // Validate an Email verification code was entered before submit
  validateEmailConfirmationForm() {
    return this.state.emailConfirmationCode.length > 0;
  }

  // Handles watching input for state changes
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  // once the component mounts, retrieve the Client Info details from
  //  the client info end point
  componentDidMount(){

    // EXAMPLE qs: 
    // response_type=code&client_id=6c12dff4-24e7-4475-a742-b08972c4ea27&redirect_uri=http://localhost:8000 
  
    // Get the client id from the query string, if none then display error
    var qs = queryString.parse(this.props.location.search);
    // console.log(qs);

    if(qs.mirthVerifierTimeout) {
      mirthVerifierTimeout = qs.mirthVerifierTimeout; 
      // console.log('I see you want a shorter timeout, enjoy the timeout ... ', mirthVerifierTimeout)
    }
    
    // console.log('Now we are using the mirthVerifierTimeout ', mirthVerifierTimeout);

    if (!qs.client_id || !qs.redirect_uri || !qs.response_type) {
      this.setState(
        {
          error: 'invalid authorization request',
          isLoading: false,
          isLoaded: false
        }
      )
    } else {
      // console.log('Using ', hsx_getClientInfo_url);
      // If a client_id was supplied, proceed with retrieiving the client details from ClientInfo
      httpClient.defaults.baseURL = hsx_getClientInfo_url + qs.client_id;
  
        httpClient.get().then(
  
          (response) => {

            if(response.message === 'ClientInfoInvalid') {
              // Invalid client, client id was probably not configured. Display an error
              this.setState(
                { 
                  error: 'Service Unavailable',
                  isLoading: false,
                  isUnavailable: true,
                  isLoaded: false
                }
              );
            } else {

              if (qs.redirect_uri !== response.data.client_callback_url) {
                // console.log(qs.redirect_uri + ' ' + response.data.client_callback_url)
                this.setState(
                  { 
                    error: 'invalid authorization request',
                    isLoading: false,
                    isUnavailable: true,
                    isLoaded: false
                  }
                );
              } else {

                let request_state = null;
                if (qs.state) {
                  request_state = qs.state;
                }

                // Successfully retrieved client details from ClientInfo, set appropriate state variables 
                this.setState({
                  client_id: qs.client_id,
                  request_redirect_uri: qs.redirect_uri,
                  request_state: request_state,
                  client_logo_url: response.data.client_logo_url,
                  client_name: response.data.client_name,
                  client_scopes: response.data.oauth_scopes,
                  client_callback_url: response.data.client_callback_url,
                  isLoaded: true,
                  error: null
                });
              }
            }
          }
        ).catch((error) => {
          // Error making a request to ClientInfo, this should be a timeout from that end point. Display and error.
          // console.log('Got an error: ', error);
          this.setState(
            { 
              error: 'Service Unavailable, please try again later.',
              isLoading: false,
              isLoaded: false
            }
          )
        });
    }

  }

  // Scope Acceptance Handler function
  //  This function will answer the final Challenge which is for accepting or rejecting the scopes
  //  presented.
  handleScopeAcceptance = async (choice) => {

    // console.log('Handling scope acceptance, user answered with ', choice);
    let answer;

    if (choice === 'ACCEPTED') {
      // console.log('The user has accepted.');
      answer = JSON.stringify(this.state.client_scopes.map( 
        scopeObj => {
          return scopeObj.scope_name 
        }
      ))

      var headers = {
        'Accept': 'text/html'
      }
  
      //httpClient.defaults.timeout = 5000;
      httpClient.post(hsx_authorize_url,
        {
          "action": "RESPOND_TO_AUTH_CHALLENGE",
          "username": this.state.email,
          "answer": answer,
          "session": this.state.session,
          "oauthParams": {
            "state": this.state.request_state,
            "redirect_uri": this.state.request_redirect_uri, 
            "client_id": this.state.client_id,
            "scopes": this.state.client_scopes
          }
        },{headers: headers}
      )
      .then(
        (response) => {
            // console.log(response);
            window.location = response.headers.location;
              // this.setState(
              //   { 
              //     error: "You have chosen to not share your data.",
              //     isLoading: false,
              //     isUnverified: true
              //   }
              // );
          }
        )
        .catch((error) => {
          // Error making a request to ClientInfo, this should be a timeout from that end point. Display and error.
          // console.log('Got an error: ', error);
            if(error.code === 'InvalidLambdaResponseException') {
              this.setState(
                { 
                  error: "Email Code was invalid",
                  isLoading: false
                }
              );
            } else {
              this.setState(
                { 
                  error: error.message,
                  isLoading: false
                }
              );
            }
        });  
    } else if (choice === 'REJECTED') {
        // console.log('The user has rejected.');
        let baseUrl = this.state.request_redirect_uri;
        let errorDesc = '?error_description=The+user+did+not+accept+the+scopes.';
        // let errorUri = '&error_uri=https%3A%2F%2Foauth2server.com%2Ferror%2Faccess_denied';
        let redirectErrorUrl = baseUrl + errorDesc;
        window.location = redirectErrorUrl;
    } else {
        answer = null;
    }
  }


  handleMirthVerifier(response){
    
    let theSession = response.data.Session;

          const mirthVerifingLoop = setInterval(
            async () => {

              //httpClient.defaults.timeout = 5000;
              httpClient.post(hsx_authorize_url,
                {
                  "action": "RESPOND_TO_AUTH_CHALLENGE",
                  "username": this.state.email,
                  "answer": '0',
                  "session": theSession,
                  "oauthParams": {
                    "state": this.state.request_state,
                    "redirect_uri": this.state.request_redirect_uri,
                    "client_id": this.state.client_id
                  }
                }
              ).then(
                (response) => {
                    if (response.data.ChallengeParameters.challengeMetadata === 'SCOPES_VERIFIER') {
                      this.setState({ 
                        user: this.state.email,
                        session: response.data.Session,
                        scopeChallengeComplete: true,
                        isLoading: false,
                        error: null
                      });
                      // console.log('Inside ', mirthVerifierTimeoutFunction);
                      clearTimeout(mirthVerifierTimeoutFunction);
                      clearInterval(mirthVerifingLoop);
                    } else {
                      theSession = response.data.Session;
                      this.setState({ 
                        user: this.state.email,
                        session: response.data.Session,
                        error: null
                      });
                    }
                  }
                ).catch((error) => {
                  // console.log('Got an error: ', error);
                    if(error.code === 'InvalidLambdaResponseException') {
                      this.setState(
                        { 
                          error: "HSX was unable to verify you in our system.",
                          isLoading: false,
                          isUnverified: true
                        }
                      );
                    } else {
                      this.setState(
                        { 
                          error: "HSX was unable to verify you in our system.",
                          isLoading: false,
                          isUnverified: true
                        }
                      );
                    }
                });      
            }, 5000
          )
  
          // console.log('Looking at a timeout of ', mirthVerifierTimeout)
          mirthVerifierTimeoutFunction = setTimeout(
            () => {
              clearInterval(mirthVerifingLoop);
              // console.log('Reached the max number of retires. Cancel the request.');
      
              //https://www.oauth.com/oauth2-servers/authorization/the-authorization-response/
      
              let baseUrl = this.state.request_redirect_uri;
              let errorDesc = '?error_description=HSX+was+unable+to+verify+the+patient+in+their+system.';
              // let errorUri = '&error_uri=https%3A%2F%2Foauth2server.com%2Ferror%2Faccess_denied';
              let redirectErrorUrl = baseUrl + errorDesc;
              window.location = redirectErrorUrl;
      
              //
              // Code below will keep the user in the app, taking them back to the origin sign up form
              //
              // this.setState(
              //   { 
              //     error: "HSX was unable to verify you in our system.",
              //     isLoading: false,
              //     isUnverified: true
              //   }
              // );
            }, mirthVerifierTimeout);
            // console.log('AFTER: ', mirthVerifierTimeoutFunction);
  }
  
  
  handleEmailConfirmationSubmit = async (e) => {

    e.preventDefault();

    // console.log('Handling Email Verification, awaiting User input.');

    this.setState({ isLoading: true });

    //httpClient.defaults.timeout = 5000;
    httpClient.post(hsx_authorize_url,
      {
        "action": "RESPOND_TO_AUTH_CHALLENGE",
        "username": this.state.email,
        "answer": this.state.emailConfirmationCode,
        "session": this.state.session,
        "oauthParams": {
          "state": this.state.request_state,
          "redirect_uri": this.state.request_redirect_uri,
          "client_id": this.state.client_id
        }
      }
    ).then(
      (response) => {
          // Change the state, add the new user data with the session, tick the smsChallengeSent to true
          //   and turn off the isLoading
          if(response.data.ChallengeParameters.challengeMetadata === 'EMAIL_MFA') {
            this.setState(
              { 
                error: "EMAIL Code was invalid. Try again.",
                session: response.data.Session,
                isLoading: false
              }
            );
          } else {
            this.setState({ 
              user: this.state.email,
              session: response.data.Session,
              emailChallengeComplete: true,
              isLoading: false,
              error: null
            },
            this.handleMirthVerifier(response)
            );
          }

        }
      ).catch((error) => {
        // Error making a request to ClientInfo, this should be a timeout from that end point. Display and error.
        // console.log('Got an error: ', error);
          if(error.code === 'InvalidLambdaResponseException') {
            this.setState(
              { 
                error: "Email Code was invalid",
                isLoading: false
              }
            );
          } else {
            this.setState(
              { 
                error: error.message,
                isLoading: false
              }
            );
          }
      });
    
  }

  handleSmsConfirmationSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    // console.log('Handling SMS Verification, awaiting User input.', this.state.session);
    this.setState({ isLoading: true });

    //httpClient.defaults.timeout = 5000;
    httpClient.post(hsx_authorize_url,
      {
        "action": "RESPOND_TO_AUTH_CHALLENGE",
        "username": this.state.email,
        "answer": this.state.smsConfirmationCode,
        "session": this.state.session,
        "oauthParams": {
          "state": this.state.request_state,
          "redirect_uri": this.state.request_redirect_uri,
          "client_id": this.state.client_id
        }
      })
      .then(
        (response) => {
          if(response.data.ChallengeParameters.challengeMetadata === 'SMS_MFA') {
            this.setState(
              { 
                error: "SMS Code was invalid. Try again.",
                session: response.data.Session,
                isLoading: false
              }
            );
          } else {
            this.setState({ 
              user: this.state.email,
              session: response.data.Session,
              smsChallengeComplete: true,
              emailChallengeSent: true,
              isLoading: false,
              error: null
            });
          }

        }
      )
      .catch(
        (error) => {
          // Error making a request to ClientInfo, this should be a timeout from that end point. Display and error.
          // console.log('Got an error: ', error);
            if(error.code === 'InvalidLambdaResponseException') {
              this.setState(
                { 
                  error: "SMS Code was invalid",
                  isLoading: false
                }
              );
            } else {
              this.setState(
                { 
                  error: error.message,
                  isLoading: false
                }
              );
            }
        }
      ); 
  }


  handleSignUpSubmit = async event => {

    event.preventDefault();

    this.setState({ isLoading: true });

    setTimeout(

      () => {

        let phoneNumber = '';
        if (this.state.phone_number) {
          phoneNumber = parseNumber(this.state.phone_number, 'US');
          if (phoneNumber !== {}) {
            phoneNumber = '+1' + phoneNumber.phone;
          }
        }
        let userPassword = Math.floor(100000 + Math.random() * 900000).toString() + 'AwsHsx!';

        //httpClient.defaults.timeout = 10000;
        httpClient.post(hsx_authorize_url,
            {
              "action": "INITIATE_AUTH",
              "email": this.state.email,
              "password": userPassword,
              "userAttributes": [
                  {"Name": "phone_number", "Value": phoneNumber},
                  {"Name":"email", "Value": this.state.email},
                  {"Name":"given_name", "Value": this.state.given_name},
                  {"Name":"middle_name", "Value": this.state.middle_name},
                  {"Name":"family_name", "Value": this.state.family_name},
                  {"Name":"birthdate", "Value": this.state.birthdate},
                  {"Name":"gender", "Value": this.state.gender}
              ],
              "oauthParams": {
                "state": "thisissomereallygreatstate",  // query string param this
                "redirect_uri": "localhost:3001",   // query string param this as well
                "client_id": this.state.client_id
              }
            })
            .then(
              (response) => {
                this.setState({ 
                  user: this.state.email,
                  session: response.data.Session,
                  smsChallengeSent: true,
                  isLoading: false,
                  error: null
                });
              }
            )
            .catch((error) => {
              // Error making a request to ClientInfo, this should be a timeout from that end point. Display and error.
              // console.log('Got an error: ', error);
              this.setState(
                { 
                  error: error.message,
                  isLoading: false
                }
              );
            }); // END HTTP POST 
      },
      5000
    ); // END TIMEOUT FUNCTION

  }

  renderEmailConfirmationForm() {

    // console.log('Displaying Email Confirmation form.');

    return (
      <form onSubmit={(e) => this.handleEmailConfirmationSubmit(e)}>
        <FormGroup controlId="emailConfirmationCode" bsSize="large">
          <ControlLabel>Email Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.emailConfirmationCode}
            onChange={this.handleChange}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateEmailConfirmationForm()}
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderSmsConfirmationForm() {

    // console.log('Displaying SMS Confirmation form.');

    return (
      <form onSubmit={(e) => this.handleSmsConfirmationSubmit(e)}>
        <FormGroup controlId="smsConfirmationCode" bsSize="large">
          <ControlLabel>SMS Confirmation Code</ControlLabel>
          <FormControl autoFocus type="tel" value={this.state.smsConfirmationCode} onChange={this.handleChange}/>
          <HelpBlock>Please check your phone for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton block bsSize="large" disabled={!this.validateSmsConfirmationForm()} isLoading={this.state.isLoading} text="Verify" loadingText="Verifying…" />
      </form>
    );
  }

  renderHsxConfirmationForm() {

    // console.log('Displaying HSX Confirmation form.');

    return (
      <form>
        <FormGroup controlId="hsxVerifier" bsSize="large">
          <ControlLabel>HSX Verification</ControlLabel>
          <HelpBlock>Please wait while we search for your patient record in our systems.</HelpBlock>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </FormGroup>
      </form>
    );
  }

  renderScopeConsentForm() {

    // console.log('Displaying Scope consent form.');
    let scopes = [];
    this.state.client_scopes.forEach(element => {
        scopes.push(element.scope_description);
    });

    return (
      <form>
        <FormGroup controlId="hsxVerifier" bsSize="large">
          <ControlLabel>HSX Verification</ControlLabel>
          <HelpBlock>By clicking the ACCEPT button, you are granting {this.state.client_name} access to the following information:</HelpBlock>
          {scopes.map(function(name, index){
            return <p key={ index }>{name}</p>;
          })}
        </FormGroup>
        <Button block bsStyle="success" bsSize="large" onClick={() => {this.handleScopeAcceptance('ACCEPTED')}}>Accept Scopes</Button>
        <Button block bsStyle="danger" bsSize="large" onClick={() => {this.handleScopeAcceptance('REJECTED')}}>Reject Scopes</Button> 
      </form>
    );
  }

  
  renderForm() {

    return (
      <form>
        <fieldset disabled={this.state.isUnavailable}>
          <div className="panel panel-primary">
            <div className="panel-heading">
            </div>
            <div className="panel-body">
              <FormGroup controlId="email" bsSize="large">
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="phone_number" bsSize="large">
                <ControlLabel>Telephone</ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.phone_number}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="given_name" bsSize="large">
                <ControlLabel>First Name</ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.given_name}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="middle_name" bsSize="large">
                <ControlLabel>Middle Name</ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.middle_name}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="family_name" bsSize="large">
                <ControlLabel>Last Name</ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.family_name}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="birthdate" bsSize="large">
                <ControlLabel>Birth Date</ControlLabel>
                <FormControl
                  type="date"
                  value={this.state.birthdate}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="gender" bsSize="lg">
              <ControlLabel>Gender</ControlLabel>
                  <FormControl 
                    componentClass="select"
                    onChange={this.handleChange}>
                      <option value="">Gender</option>
                      <option value="m">Male</option>
                      <option value="f">Female</option>
                      <option value="u">Unknown</option>
                  </FormControl>
              </FormGroup>
              <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Signup"
                onClick={this.handleSignUpSubmit}
                loadingText="Signing up…"
              />
            </div>
            <div className="panel-footer"></div>
          </div>
        </fieldset>
      </form>
    );
  }




  render() {


  if (this.state.isUnverified && this.state.error) {
    // console.log('Displaying mirth verifier failure form....');
    return (
      <div className="Signup">
        <form>
          <FormGroup controlId="hsxVerifier" bsSize="large">
              <HelpBlock>
                <img src={"/hsx_alpha_icon.png"} alt="HSX"/>
                <img src={this.state.client_logo_url} alt="HSX" width="150px" height="auto" /> 
                <h2>Connect with HSX</h2>
                <p>Linking your HSX data with {this.state.client_name}</p>
              </HelpBlock>
          </FormGroup>
          {this.state.error ? <Alert className="alert-danger">{this.state.error}</Alert> : null}
        </form>
        {this.state.user === null ? this.renderForm() : this.renderForm()}
      </div>
    );
  }


    if (this.state.smsChallengeSent && !this.state.smsChallengeComplete && !this.state.emailChallengeSent && !this.state.emailChallengeComplete && !this.state.scopeChallengeComplete && !this.state.scopesAccepted && !this.state.scopesRejected) {
      // console.log('Triggering the SMS Form display...');
      return (
        <div className="Signup">
          <form>
            <FormGroup controlId="hsxVerifier" bsSize="large">
                <HelpBlock>
                  <img src={"/hsx_alpha_icon.png"} alt="HSX"/>
                  <img src={this.state.client_logo_url} alt="HSX" width="150px" height="auto" /> 
                  <h2>Connect with HSX</h2>
                  <p>Linking your HSX data with {this.state.client_name}</p>
                </HelpBlock>
            </FormGroup>
            {this.state.error ? <Alert className="alert-danger">{this.state.error}</Alert> : null}
          </form>
          {this.renderSmsConfirmationForm()}
        </div>
      );
    } else if (this.state.smsChallengeComplete && this.state.emailChallengeSent && !this.state.emailChallengeComplete && !this.state.scopeChallengeComplete && !this.state.scopesAccepted && !this.state.scopesRejected) {
      // console.log('Triggering the Email Form display...');
      return (
        <div className="Signup">
          <form>
            <FormGroup controlId="hsxVerifier" bsSize="large">
                <HelpBlock>
                  <img src={"/hsx_alpha_icon.png"} alt="HSX"/>
                  <img src={this.state.client_logo_url} alt="HSX" width="150px" height="auto" /> 
                  <h2>Connect with HSX</h2>
                  <p>Linking your HSX data with {this.state.client_name}</p>
                </HelpBlock>
            </FormGroup>
            {this.state.error ? <Alert className="alert-danger">{this.state.error}</Alert> : null}
          </form>
          {this.renderEmailConfirmationForm()}
        </div>
      );
    } else if (this.state.smsChallengeComplete && this.state.emailChallengeComplete && !this.state.scopeChallengeComplete && !this.state.scopesAccepted && !this.state.scopesRejected) {
      // console.log('Triggering the HSX Verifier display...');
      return (
        <div className="Signup">
          <form>
            <FormGroup controlId="hsxVerifier" bsSize="large">
                <HelpBlock>
                  <img src={"/hsx_alpha_icon.png"} alt="HSX"/>
                  <img src={this.state.client_logo_url} alt="HSX" width="150px" height="auto" /> 
                  <h2>Connect with HSX</h2>
                  <p>Linking your HSX data with {this.state.client_name}</p>
                </HelpBlock>
            </FormGroup>
            {this.state.error ? <Alert className="alert-danger">{this.state.error}</Alert> : null}
          </form>
          {this.renderHsxConfirmationForm()}
        </div>
      );
    } else if (this.state.scopeChallengeComplete && this.state.emailChallengeComplete && this.state.smsChallengeComplete && this.state.scopeChallengeComplete && !this.state.scopesAccepted && !this.state.scopesRejected) {
      // console.log('Triggering the Scope consent display...');
      return (
        <div className="Signup">
          <form>
            <FormGroup controlId="hsxVerifier" bsSize="large">
                <HelpBlock>
                  <img src={"/hsx_alpha_icon.png"} alt="HSX"/>
                  <img src={this.state.client_logo_url} alt="HSX" width="150px" height="auto" /> 
                  <h2>Connect with HSX</h2>
                  <p>Linking your HSX data with {this.state.client_name}</p>
                </HelpBlock>
            </FormGroup>
            {this.state.error ? <Alert className="alert-danger">{this.state.error}</Alert> : null}
          </form>
          {this.renderScopeConsentForm()}
        </div>
      );
    } else if (this.state.isLoaded && !this.state.scopeChallengeComplete && !this.state.emailChallengeComplete && !this.state.smsChallengeComplete && !this.state.scopeChallengeComplete && !this.state.scopesAccepted && !this.state.scopesRejected) {
        // console.log('Displaying signup form....');
        return (
          <div className="Signup">
            <form>
              <FormGroup controlId="hsxVerifier" bsSize="large">
                  <HelpBlock>
                    <img src={"/hsx_alpha_icon.png"} alt="HSX"/>
                    <img src={this.state.client_logo_url} alt="HSX" width="150px" height="auto" /> 
                    <h2>Connect with HSX</h2>
                    <p>Linking your HSX data with {this.state.client_name}</p>
                  </HelpBlock>
              </FormGroup>
              {this.state.error ? <Alert className="alert-danger">{this.state.error}</Alert> : null}
            </form>
            {this.state.user === null ? this.renderForm() : this.renderForm()}
          </div>
        );
    } else if (this.state.error && !this.state.isLoaded) {
      // console.log('Displaying error loading form....');
      return (
        <div className="Signup">
          <form>
            <FormGroup controlId="hsxVerifier" bsSize="large">
                <HelpBlock>
                  <img src={"/hsx_alpha_icon.png"} alt="HSX"/>
                  <img src={this.state.client_logo_url} alt="HSX" width="150px" height="auto" /> 
                  <h2>Connect with HSX</h2>
                  <p>Linking your HSX data with {this.state.client_name}</p>
                </HelpBlock>
            </FormGroup>
            <Alert className="alert-danger">{this.state.error}</Alert>
          </form>
        </div>
      );
    } else {
      // console.log('Displaying loading form....');
        return (
          <div className="Signup">
            <form>
              <Alert className="alert-primary">Please wait while we load...</Alert>
              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </form>
          </div>
        );
    }
  }
}